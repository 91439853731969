<template>
  <div class="gocc">
    <van-popup v-model="show" :close-on-click-overlay="false" round><img src="@/assets/images/Customer-service-waiting.gif" alt=""></van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: true
    }
  },
  async created() {
    this.createDialogue();
    // console.log(this.$router,sessionStorage.getItem('go_cc'),(sessionStorage.getItem('go_cc')+'')=='null');
    // if((sessionStorage.getItem('go_cc')+'')=='null'){
    //   sessionStorage.setItem("go_cc","1");
    //   await this.createDialogue();
    // }else{
    //   if(sessionStorage.getItem("go_cc") == 2){
    //     await this.createDialogue();
    //   }else if(sessionStorage.getItem("go_cc")==3){
    //     if((sessionStorage.getItem('goType')+'') == 'null'){
    //       history.go(-1);
    //     }else{
    //       history.go(-3);
    //     }
    //   }
    //   // sessionStorage.removeItem('go_cc');
    // }
  },
  beforeDestroy(){
    // sessionStorage.removeItem('go_cc');
  },
  methods: {
    //
    async createDialogue() {
      const res = await this.$imapiAxio.post(
        '/im/normal-chat-create',
        {toUserId:this.$route.params.toUserId}
      )
      // console.log(res);
      if(res.errorCode == 0){
        this.$router.push({
          path: '/im/chat',
          query: {
            chatId: res.data.chatId
          }
        })
      }else{
        this.$toast.error('！');
        this.$router.go(-1);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.gocc{
  img{
    width: 8rem;
    height: 8rem;
  }
}
</style>
